#portfolio {
    background-color: #D0CCCA !important;
}

.productsText {
    background: rgba(244, 98, 58,0.8);
    color: #fff;
    transition: opacity .5s;
    opacity: 0;
    position: absolute;
    top: 1em; bottom: 1em; left: 1em; right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.wrap {
    display: inline-block;
    position: relative;
  }
  
.wrap:hover .productsText {
    opacity: 1;
}
