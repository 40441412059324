.employee .img-fluid {
    position: relative; 
    left: 0; 
    top: 0;
    opacity: 0.7;
  }
  
  .employee .portfolio-box-caption .icon-links {
    position: absolute;
    left:50%;
    top: 50%;
    margin-left:-20px;
    margin-top: -20px;
    display: none;
  }
  
  .employee .img-fluid:hover{
    opacity: 1;
  }
  
  .employee:hover > .portfolio-box-caption .icon-links {
    display: inline;
  }
  .employee-desc-header {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #212529;
  }
  
  .employee-desc-body {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #212529;
  }
  .space-between {
      padding-right: 10px;
  }